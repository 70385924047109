<template>
  <div @click="goBack" class="d-flex justify-content-center align-items-center">
    <feather class="back-icon" type="arrow-left"></feather>
  </div>
</template>

<script>
export default {
  name: 'BackButton.vue',
  props: {
    manual: Boolean,
  },
  mounted() {
    this.$on('goBack', () => {
      this.routerBackwards();
    });
  },
  methods: {
    goBack() {
      if (this.manual) {
        return;
      }
      this.routerBackwards();
    },
    routerBackwards() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
  .back-icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
</style>
