import { VIRTUAL_PT_PROGRAM_TYPE } from '@/scripts/constants';

/**
 * This function sets the `has_provider_enabled` value based on whether or not. If the `program_type` of the user is
 * not VIRTUAL_PT, then we want to automatically set `has_provider_type` to false.
 *
 * @param user - the user object
 * @returns {{has_provider_enabled}|*}
 */
function convertUserHasProviderEnabled(user) {
  if (!user) {
    return user;
  }

  user.has_provider_enabled = !!((user.program_type === VIRTUAL_PT_PROGRAM_TYPE) && user.has_provider_enabled);

  return user;
}

function downloadFile(arrayBuffer, fileName) {
  const fileURL = URL.createObjectURL(new Blob([ arrayBuffer ]), { type: 'octet/stream' });
  const fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);

  fileLink.click();
  URL.revokeObjectURL(fileLink.href);
  document.body.removeChild(fileLink);
}

export default { convertUserHasProviderEnabled, downloadFile };
