<template>
  <div class="container mt-4">
    <b-row>
      <b-col class="d-flex align-items-center">
        <BackButton></BackButton>
        <h5 class="ml-2 mb-0">
          {{ translations.details.back_list }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card
        data-testid="details-card"
        class="border-0"
        :header="translations.details.title"
        header-class="border-0">
          <b-row>
            <b-col cols="3">
              <b>{{ translations.details.statement_id }}</b>
              <p>{{ details.id || '-' }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.details.rvp_name }}</b>
              <p>{{ details.rvp_name || '-' }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.details.rvp_id }}</b>
              <p>{{ details.rvp_salesforce_id || '-' }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.details.rvp_email }}</b>
              <p>{{ details.rvp_email || '-' }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <b>{{ translations.details.total_balance }}</b>
              <p>{{ details.total_balance || '-' }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.details.start_date }}</b>
              <p>{{ formatDate(details.start_date) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.details.end_date }}</b>
              <p>{{ formatDate(details.end_date) }}</p>
            </b-col>
            <b-col cols="3">
              <b>{{ translations.details.created_at }}</b>
              <p>{{ formatDatetime(details.created_at) }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card class="border-0" header-class="border-0">
          <template #header>
            <div class="d-flex align-items-center justify-content-between">
              {{ translations.details.summary.title }}
              <b-button
              variant="secondary"
              @click="exportSummary"
              :disabled="!summaryItems.length"
              class="compact-text">
                <feather type="download"></feather>
                {{ translations.details.summary.btn_export }}
              </b-button>
            </div>
          </template>
          <div class="table-responsive">
            <table class="table table-striped" data-testid="summaries-table">
              <thead>
                <tr>
                  <th scope="col" class="text-center">{{ translations.details.summary.plan_id }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.deal_id }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.deal }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.channel_deal }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.launch_date }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.product }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.transaction_type }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.gross_revenue }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.rev_share }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.net_revenue }}</th>
                  <th scope="col" class="text-center">{{ translations.details.summary.commission }}</th>
                </tr>
              </thead>
              <tbody v-if="summaryItems.length">
                <tr v-for="(item, key) in summaryItems" :key="key">
                  <td class="text-center">{{ item.plan_id || '-' }}</td>
                  <td class="text-center">{{ item.deal_id || '-' }}</td>
                  <td class="text-center">{{ splitName(item.deal_name) }}</td>
                  <td class="text-center">{{ splitName(item.original_channel_deal_name) }}</td>
                  <td class="text-center">{{ formatDate(item.sword_launch_date) }}</td>
                  <td class="text-center">{{ item.opportunity_product_code || '-' }}</td>
                  <td class="text-center">{{ item.transaction_type || '-' }}</td>
                  <td class="text-center">{{ item.finance_gross_revenue || '-' }}</td>
                  <td class="text-center">{{ item.finance_revenue_share || '-' }}</td>
                  <td class="text-center">{{ item.finance_net_revenue || '-' }}</td>
                  <td class="text-center">{{ item.total_balance || '-' }}</td>
                </tr>
                <tr>
                  <td colspan="10" class="text-right font-weight-bold">{{ translations.details.summary.total_amount }}</td>
                  <td class="text-center font-weight-bold">{{ summary.total || '-' }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="11" class="text-center">{{ translations.details.summary.empty }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card class="border-0" header-class="border-0">
          <template #header>
            <div class="d-flex align-items-center justify-content-between">
              {{ translations.details.transactions.title }}
              <b-button
              variant="secondary"
              @click="exportTransactions"
              :disabled="!transactions.data.length"
              class="compact-text">
                <feather type="download"></feather>
                {{ translations.details.transactions.btn_export }}
              </b-button>
            </div>
          </template>
          <financial-list
          data-testid="transactions-table"
          v-model="transactions.currentPage"
          :header-data="transactionHeaders"
          :data="transactions.data"
          :items-per-page="transactions.itemsPerPage"
          @page-changed="fetchTransactions"
          :total="transactions.total"
          :disabled="transactions.loading"
          :no-items-message="translations.details.transactions.empty"/>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import translations from '@/translations';
import BackButton from '@/components/BackButton.vue';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import { parseResponseError } from '@/http/parsers/parse_response';
import service from '@/services/commission';
import utils from '@/scripts/tools/utils';
import { formatDate, formatDatetime } from '@/helpers/finance';

export default {
  name: 'Details',
  components: {
    BackButton,
    FinancialList,
  },
  data() {
    return {
      translations: translations.commissions.statements,
      statementId: Number(this.$route?.params?.statementId || 0),
      details: {},
      summary: {},
      transactions: {
        currentPage: 1,
        itemsPerPage: 10,
        total: 0,
        data: [],
        loading: false,
      },
    };
  },
  computed: {
    transactionHeaders() {
      return [
        associateHeaderDataItem(this.translations.details.transactions.id, 'id'),
        associateHeaderDataItem(this.translations.details.transactions.deal, 'deal_name'),
        associateHeaderDataItem(this.translations.details.transactions.channel_deal, 'original_channel_deal_name'),
        associateHeaderDataItem(this.translations.details.transactions.transaction_type, 'transaction_type'),
        associateHeaderDataItem(this.translations.details.transactions.deal_product, 'opportunity_product_code'),
        associateHeaderDataItem(this.translations.details.transactions.commission, 'transaction_value'),
        associateHeaderDataItem(this.translations.details.transactions.entry_type, 'transaction_value_type'),
        associateHeaderDataItem(this.translations.details.transactions.plan, 'plan_name'),
        associateHeaderDataItem(this.translations.details.transactions.payable_date, 'payable_date'),
        associateHeaderDataItem(this.translations.details.transactions.transaction_date, 'transaction_date'),
      ];
    },
    summaryItems() {
      return this.summary?.items || [];
    },
  },
  async beforeMount() {
    await Promise.all([
      this.fetchDetails(),
      this.fetchSummary(),
      this.fetchTransactions(this.transactions.currentPage),
    ]);
  },
  methods: {
    formatDate,
    formatDatetime,
    async fetchDetails() {
      try {
        this.details = await service.getStatement(this.statementId);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_details}: ${parseResponseError(err)}`);
      }
    },
    async fetchSummary() {
      try {
        this.summary = await service.getStatementSummary(this.statementId);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_summary}: ${parseResponseError(err)}`);
      }
    },
    async fetchTransactions(page) {
      try {
        this.transactions.loading = true;
        const { transactions, total } = await service.getTransactions({
          statement_id: this.statementId,
          offset: (page - 1) * this.transactions.itemsPerPage,
          limit: this.transactions.itemsPerPage,
          order_by: 'id',
          order_descending: true,
        });

        this.transactions.data = transactions?.map(t => {
          t.deal_name = this.splitName(t.deal_name);
          t.original_channel_deal_name = this.splitName(t.original_channel_deal_name);
          t.payable_date = formatDatetime(t.payable_date);
          t.transaction_date = formatDatetime(t.transaction_date);
          return t;
        });
        this.transactions.currentPage = page;
        this.transactions.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_transactions}: ${parseResponseError(err)}`);
      } finally {
        this.transactions.loading = false;
      }
    },
    async exportSummary() {
      try {
        const buf = await service.exportStatementSummary(this.statementId);
        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `commission_statement_${this.statementId}_summary_${dateStr}.csv`;
        utils.downloadFile(buf, fileName);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.export_csv}: ${parseResponseError(err)}`);
      }
    },
    async exportTransactions() {
      try {
        const buf = await service.exportTransactions({ statement_id: this.statementId });
        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `commission_transactions_export_${dateStr}.csv`;
        utils.downloadFile(buf, fileName);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.export_csv}: ${parseResponseError(err)}`);
      }
    },
    splitName(name) {
      if (!name) return '-';
      return name.split('|')[0];
    },
  },
};
</script>
